<template>
  <v-row>
    <v-col
      cols="12"
      lg="8"
      md="12"
    >
      <v-row>
        <v-col cols="12" class="pt-0">
          <chart-card
            :title="selectionTitle"
            :subtitle="' Correct Result +5, Correct Winning Margin +8, Draw +17'"
            :button-text="hasMadeSelection ? 'More' : ''"
            :childFunctionOne="editSelections"
            :childFunctionTwo="lockSelections"
          >
            <template slot="chart">
              <v-col cols="12">
                <v-data-iterator
                  :items="getInPlayFixtures"
                  :items-per-page.sync="itemsPerPage"
                  :page="page"
                  hide-default-footer
                >
                  <template v-slot:default="props">
                    <v-row justify="center">
                      <v-col
                        v-for="(item, index) in props.items"
                        :key="index"
                        cols="12"
                        lg="8"
                        class="pa-0 mb-2"
                      >
                        <list-card-row
                          :id="`match-${index}`"
                          :ref="`match-${index}`"
                          :fixture="item"
                          :progressbar-color="item.progressColor"
                          :editing="editing"
                          footer-btn="CHAT"
                          footer-btn-color="default"
                          footer-btn-two="PROFILE"
                          footer-btn-color-two="default"
                          class="fixture"
                          @marginSelected="selectionMade(index)"
                          @marginUnSelected="undoSelection(index)"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </template>
          </chart-card>
        </v-col>
      </v-row>
      <v-row v-if="(!hasMadeSelection || editing) && getCompetitionPredictionsOpen">
        <v-col>
          <v-card>
            <v-row
              align="center"
              justify="center"
            >
              <v-col class="ml-2">
                <p class="ml-2 mb-0">
                  {{ selectionsMadeText }}
                </p>
              </v-col>
              <v-spacer />
              <v-col class="text-right mr-2" v-if="editing">
                <v-btn
                  class="grey"
                  :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                  @click="editing = false"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col class="text-right mr-2">
                <v-btn
                  class="secondary"
                  :disabled="!enabledConfirmation"
                  @click="doConfirmation"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-card>
            <v-row>
              <v-spacer v-if="this.$vuetify.breakpoint.smAndUp" />
              <v-col class="d-flex justify-center">
                <p class="mb-0">
                  {{ getCompetitionPredictionsOpen ? 'Selections Confirmed' : 'Predictions Closed' }}
                </p>
              </v-col>
              <v-spacer v-if="this.$vuetify.breakpoint.smAndUp"/>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      lg="4"
      md="12"
    >
      <base-card>
        <v-card-title>
          Previous Predictions
        </v-card-title>
        <v-card-text>
          <div>
            <v-select
                id="matchdaySelect"
                :items="previousGameweeks"
                label="Matchday"
                @change="updateGameweek"
                v-model="selectedPredictions"
            />
          </div>
          <div v-for="prediction in previousPredictions" :key="prediction.id">
            <list-card-row-result
              :id="`prediction-${prediction.id}`"
              :ref="`prediction-${prediction.id}`"
              :summary="true"
              :fixture="transformPrediction(prediction.fixtureEnt)"
              :prediction="prediction"
              :resultLabel="prediction.winningMargin.label"
              :winnerLabel="prediction.matchResult"
              footer-btn="CHAT"
              footer-btn-color="default"
              footer-btn-two="PROFILE"
              footer-btn-color-two="default"
              class="fixture mt-4"
            />
          </div>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col
      cols="12"
      lg="4"
      md="12"
    >
      <div class="py-3"></div>
    </v-col>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="color"
      bottom right
    >
      {{ message }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="showConfirm"
      max-width="70%"
    >
      <v-card>
        <v-card-title class="text-h5 success lighten-2">
          <span>Lock Selection</span>
          <v-spacer/>
          <v-icon>mdi-lock-outline</v-icon>
        </v-card-title>

        <v-card-text class="mt-2">
          Locking selection means you cannot edit your selections again until the next matchday, but get instant access to the weekly game statistics
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="white"
            text
            @click="showConfirm = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            disabled
          >
            Lock Selections
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  <script>
  import ChartCard from '@/components/card/ChartCard'
  import ListCardRow from '@/components/card/listCard/ListCardRowSelection'
  import ListCardRowResult from '@/components/card/listCard/ListCardRowSelectionResult'
  import { mapGetters, mapActions } from 'vuex'
  import store from '@/store'

  export default {
    name: 'Selection',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Selection',
    },
    components: {
      ChartCard,
      ListCardRow,
      ListCardRowResult,
    },
    async beforeRouteEnter(to, from, next) {
      // Check if the user is logged in or has a valid authentication token
      const isLoggedIn = store.getters.loggedInUser;

      // If the route requires authentication and the user is not logged in, redirect to the login page
      if (to.meta.requiresAuth && !isLoggedIn) {
        next('/login'); // Redirect to the login page
      }
      // } else if(isLoggedIn.userDetails && !isLoggedIn.userDetails.paid){
      //   const response = await fetch('https://server.knowthegame.ie/create-checkout-session');
      //   const { sessionId } = await response.json();
      //   const stripe = Stripe('pk_live_51MWiWlJgqpX8vKED2inaCvTGm0vDhwfclkNBJHcKO2mP2oW6AhKrRLfTi1ilnab8bnsavPHIq04enx0iwL5dTBFF00W27as7hU');
      //   stripe.redirectToCheckout({ sessionId });
      // }
      else {
        next(); // Continue to the requested route
      }
    },
    mounted(){
      this.fetchInPlayFixtures(3); // TODO Hardcoded Comp
      this.fetchCompetitionPredictionOpen(3); // TODO Hardcoded Comp
      this.fetchWinningMargins('HURLING'); // TODO Hardcoded Comp
      this.fetchCurrentGameweekForCompetition(3); // TODO Hardcoded Comp
      this.fetchPastSelections({userId: this.loggedInUser.userDetails.id, competitionId: 3}); // TODO Hardcoded Comp 
      this.selectedPredictions = this.getCurrentGameweek -1;
      this.pollPredictionsOpen();
    },
    data () {
      return {
        selections: [],
        tab: null,
        itemsPerPage: 6,
        page: 1,
        snackbar: false,
        message: 'Predictions entered',
        timeout: 3000,
        color: 'success',
        editing: false,
        showConfirm: false,
        confirmedResults: false,
        selectedPredictions: -1
      }
    },
    computed: {
      ...mapGetters(["getInPlayFixtures", "hasMadeSelection", 'loggedInUser', 'getCompetitionPredictionsOpen', 'getCurrentGameweek', 'getPastSelections']),
      selectionsMadeText () {
        return `${this.selections.length}/${this.getInPlayFixtures.length} Selections Made`
      },
      enabledConfirmation () {
        return this.selections.length == this.getInPlayFixtures.length
      },
      selectionTitle(){
        return this.$store.getters['hasMadeSelection'] ? 'Selections Made' : 'Make Your Selections';
      },
      previousPredictions(){
        if(this.selectedPredictions){
          return this.$store.getters['getPastSelections'].filter(prediction => prediction.fixtureEnt.matchday == this.selectedPredictions)
        }
        return this.$store.getters['getPastSelections'].filter(prediction => prediction.fixtureEnt.matchday == this.$store.getters['getCurrentGameweek'] -1)
      },
      previousGameweeks(){
        let highestNumber = this.$store.getters.getCurrentGameweek;
        let totalWeeks = [];
        for(let i=1; i<=highestNumber-2; i++){
            totalWeeks.push(i)
        }
        totalWeeks.push({ text: 'Last Week', value: this.$store.getters.getCurrentGameweek -1 });
        return totalWeeks;
      }
    },
    watch: {
      hasMadeSelection(val){
        if(val == true && this.confirmedResults == true){
          this.snackbar = true;
        }
      },
      getCurrentGameweek(val){
        if(this.loggedInUser && this.loggedInUser.userDetails){
          this.fetchGameWeekSelection({userId: this.loggedInUser.userDetails.id, gameweek: val, competitionId: 3});
          this.fetchPastSelections({userId: this.loggedInUser.userDetails.id, competitionId: 3});
          this.selectedPredictions = val -1;
        }
      }
    },
    methods: {
      ...mapActions(["fetchInPlayFixtures", "confirmSelection", "fetchWinningMargins", "fetchGameWeekSelection", "fetchCompetitionPredictionOpen", "fetchCurrentGameweekForCompetition", "fetchPastSelections"]),
      editSelections() {
        this.editing = true;
      },
      lockSelections() {
        this.showConfirm = true;
      },
      async doConfirmation() {
        this.editing = false;
        this.confirmedResults = true;
        let editedResults = await this.confirmSelection();

        this.fetchGameWeekSelection({userId: this.loggedInUser.userDetails.id, gameweek: this.getCurrentGameweek, competitionId: 3});
      },
      selectionMade (index) {
        if (this.selections.indexOf(index) == -1) {
          this.selections.push(index)
        }
        if (index < this.getInPlayFixtures.length - 1) {
          document.getElementById(`match-${index + 1}`).scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      },
      undoSelection (index) {
        if (this.selections.indexOf(index) != -1) {
          this.selections = this.selections.filter(val => val !== index)
        }
      },
      getColor (lastPrice) {
        if (lastPrice % 2 == 0) {
          return 'danger--text'
        } else if (lastPrice[lastPrice.length] == 0) {
          return 'primary--text'
        } else {
          return 'success--text'
        }
      },
      changeColor (percent) {
        if (percent.indexOf('+') > -1) {
          return 'success white--text'
        } else if (percent == '(-)') {
          return 'primary white--text'
        } else {
          return 'danger white--text'
        }
      },
      transformPrediction(fixture){
        return { 
          id: fixture.id,
            homeTeam: fixture.homeTeam.name,
            homeLogo: fixture.homeTeam.code,
            homeColour: fixture.homeTeam.colour,
            homeScore: `${fixture.homeGoals}-${fixture.homePoints}`,
            awayTeam: fixture.awayTeam.name,
            awayLogo: fixture.awayTeam.code,
            awayColour: fixture.awayTeam.colour,
            awayScore: `${fixture.awayGoals}-${fixture.awayPoints}`,
            date: new Date(fixture.date).toLocaleDateString(),
            time: new Date(fixture.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            venue: fixture.venue,
            stage: fixture.stage,
            competition: fixture.competitionEnt.name,
            matchday: fixture.matchday,
            matchResult: fixture.matchResult
        }
      },
      updateGameweek(val){
        if(val == 'Last Week'){
          this.selectedPredictions = this.$store.getters['getCurrentGameweek'] -1;
        }
      },
      pollPredictionsOpen(){
        let count = 0;
        const maxCalls = 3;
        const interval = 5000; // 5 seconds

        const intervalId = setInterval(() => {
            this.fetchCompetitionPredictionOpen(3);
            count++;
            if (count === maxCalls) {
                clearInterval(intervalId);
            }
        }, interval);
      }
    }
  }
  </script>

  <style scoped>
  .fixture {
    scroll-margin-top: 20vh;
  }
  </style>
